<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-show="editable&&!disabled&&isOld"
              label="삭제"
              icon="delete"
              @btnClicked="deleteInfo" />
            <c-btn
              v-show="editable&&!disabled"
              :url="saveUrl"
              :isSubmit="isSave"
              :param="check"
              :mappingType="mappingType"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
            <c-btn
              v-show="editable&&!disabled&&isOld"
              :url="completeUrl"
              :isSubmit="isComplete"
              :param="check"
              mappingType="PUT"
              label="완료"
              icon="check"
              @beforeAction="completeInfo"
              @btnCallback="completeCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :required="true"
              :disabled="disabled"
              :editable="editable"
              label="점검명"
              name="checkName"
              v-model="check.checkName">
            </c-text>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-select
              :required="true"
              :disabled="disabled"
              :editable="editable"
              codeGroupCd="SOP_FIRE_FIGHTING_CHECK_TYPE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              label="점검유형"
              name="sopFireFightingCheckTypeCd"
              v-model="check.sopFireFightingCheckTypeCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2">
            <c-plant
              :required="true"
              :disabled="disabled"
              :editable="editable"
              type="edit"
              name="plantCd"
              v-model="check.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="disabled"
              :editable="editable"
              range
              default="today"
              label="점검기간"
              name="period"
              v-model="check.period"
            />
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      v-if="isOld"
      ref="table1"
      class="q-mt-sm"
      title="점검 대상"
      :columns="grid.columns"
      :data="check.targets"
      :gridHeight="gridHeight"
      :editable="editable&&!disabled"
      :filtering="false"
      :columnSetting="false"
      :isExcelDown="false"
      :isTitle="true"
      :usePaging="true"
      :gridHeightAuto="true"
      selection="multiple"
      rowKey="sopFireFightingCheckTargetId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable&&!disabled" label="삭제" icon="delete" @btnClicked="deleteTarget" />
          <c-btn v-if="editable&&!disabled" label="추가" icon="add" @btnClicked="addTarget" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name !== 'custom'">
          <q-btn  
            v-if="col.name==='fireFightingName'"
            class="tableinnerBtn"
            flat
            align="center"
            color="blue-6"
            :label="props.row[col.name]"
            @click.stop="linkClick(props.row)" />
          <template v-else>
            {{props.row[col.name]}}
          </template>
          <div style="font-size:0.8em;">
            <template v-if="col.name==='sopFireFightingTypeName'">
              ({{props.row['sopFireFightingKindName']}})
            </template>
            <template v-if="col.name==='fireFightingName'">
              ({{props.row['manageNo']}})
            </template>
          </div>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'fireFightingCheckInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    check: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        sopFireFightingCheckTypeCd: null,  // 점검유형
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkStartDate: '',  // 점검시작일
        checkEndDate: '',  // 점검종료일
        period: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        imprs: [],
      }),
    },
    select: {
      type: Object,
      default: () => ({
        sopFireFightingId: '',
        sopFireFightingCheckTargetId: '',
        sopFireFightingKindCd: '',
        fireFightingName: '',
        checkResult: '',
        editFlag: '',
        results: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'sopFireFightingTypeName',
            field: 'sopFireFightingTypeName',
            label: '설비유형(종류)',
            align: 'center',
            sortable: false,
            style: 'width: 50%',
            type: 'custom',
          },
          {
            name: 'fireFightingName',
            field: 'fireFightingName',
            label: '소화설비(관리번호)',
            align: 'center',
            sortable: false,
            style: 'width: 50%',
            type: 'custom',
          },
          // {
          //   required: true,
          //   name: 'checkScheduleDate',
          //   field: 'checkScheduleDate',
          //   label: '점검예정일',
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: false,
          //   type: 'date',
          //   value: '',
          //   setHeader: true,
          // },
          // {
          //   required: true,
          //   name: "deptName",
          //   field: "deptName",
          //   style: 'width:100px',
          //   label: "점검부서",
          //   align: "center",
          //   sortable: false,
          //   type: 'dept',
          //   deptCd: 'deptCd',
          //   value: '',
          //   setHeader: true,
          // },
          // {
          //   name: "checkDate",
          //   field: "checkDate",
          //   style: 'width:100px',
          //   label: "점검일",
          //   align: "center",
          //   sortable: false,
          //   type: 'date',
          //   value: '',
          //   setHeader: true,
          // },
          // {
          //   name: "checkResult",
          //   field: "checkResult",
          //   style: 'width:100px',
          //   label: "점검결과",
          //   align: "center",
          //   sortable: false,
          //   type: 'text',
          // },
        ],
      },
      items: [],
      editable: false,
      isSave: false,
      isComplete: false,
      listItemUrl: '',
      saveUrl: '',
      deleteUrl: '',
      insertTargetUrl: '',
      deleteTargetUrl: '',
      completeUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 250) + 'px' : '500px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listItemUrl = selectConfig.sop.fft.base.item.list.url
      this.saveUrl = transactionConfig.sop.fft.fireFightingCheck.update.url
      this.deleteUrl = transactionConfig.sop.fft.fireFightingCheck.delete.url
      this.completeUrl = transactionConfig.sop.fft.fireFightingCheck.complete.url
      this.insertTargetUrl = mobileConfig.sop.fft.fireFightingCheck.target.insert.url
      this.deleteTargetUrl = mobileConfig.sop.fft.fireFightingCheck.target.delete.url
      // code setting
      // list setting
      if (this.editable && !this.disabled) {
        this.getItems();
      }
    },
    getItems() {
      this.$http.url = this.listItemUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        // plantCd: this.check.plantCd,
        // sopFireFightingKindCd: row.sopFireFightingKindCd,
        useFlag: 'Y'
      }
      this.$http.request((_result) => {
        if (_result.data && _result.data.length > 0) {
          this.items = _result.data
        }
      },);
    },
    linkClick(row) {
      this.$set(this.select, 'sopFireFightingId', row.sopFireFightingId)
      this.$set(this.select, 'sopFireFightingCheckTargetId', row.sopFireFightingCheckTargetId)
      this.$set(this.select, 'sopFireFightingKindCd', row.sopFireFightingKindCd)
      this.$set(this.select, 'fireFightingName', row.fireFightingName)
      this.$set(this.select, 'checkResult', row.checkResult)
      this.$set(this.select, 'editFlag', row.editFlag)
      this.$set(this.select, 'results', row.results)
      /**
       * 팝업 띄우기
       */
      this.popupOptions.title = '점검결과';
      this.popupOptions.param = {
        select: this.select,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'./fireFightingCheckResult.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail');
    },
    deleteTarget() {
      let selectData = this.$refs['table1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$http.url = this.deleteTargetUrl;
        this.$http.type = 'DELETE';
        this.$http.param = {
          data: Object.values(selectData),
        };
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$_.forEach(selectData, item => {
            this.check.targets = this.$_.reject(this.check.targets, item);
          })
          this.$refs['table1'].$refs['compo-table'].clearSelection();
        },);
      }
    },
    addTarget() {
      this.popupOptions.title = '소방설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.check.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/fft/fireFightingPop.vue'}`);
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeFireFightingPopup;
    },
    closeFireFightingPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.check.targets, { sopFireFightingId: item.sopFireFightingId }) === -1) {
            let _sopFireFightingCheckTargetId = uid();
            let _results = [];
            this.$_.forEach(this.$_.filter(this.items, { 
              plantCd: item.plantCd, 
              sopFireFightingKindCd: item.sopFireFightingKindCd 
            }), _item => {
              _results.push({
                sopFireFightingCheckTargetResultId: uid(),  // 소방설비 점검결과 일련번호
                sopFireFightingCheckTargetId: _sopFireFightingCheckTargetId,  // 소방설비 점검대상 일련번호
                sopFireFightingCheckId: this.popupParam.sopFireFightingCheckId,  // 소방설비 점검 일련번호
                sopFireFightingCheckItemId: _item.sopFireFightingCheckItemId,  // 소방설비 점검항목 일련번호
                sopFireFightingTypeCd: _item.sopFireFightingTypeCd,
                sopFireFightingTypeName: _item.sopFireFightingTypeName,
                itemName: _item.itemName,
                sopFireFightingCheckResultCd: 'FFCR000001',  // 점검결과
                remarks: '',  // 비고
                regUserId: this.$store.getters.user.userId,  // 등록자 ID
                editFlag: 'C'
              })
            })
            this.check.targets.splice(0, 0, { 
              sopFireFightingCheckTargetId: _sopFireFightingCheckTargetId,  // 소방설비 점검대상 일련번호
              sopFireFightingCheckId: this.popupParam.sopFireFightingCheckId,  // 소방설비 점검 일련번호
              sopFireFightingId: item.sopFireFightingId,  // 소방설비 일련번호
              fireFightingName: item.fireFightingName,  // 소방설비 일련번호
              sopFireFightingTypeName: item.sopFireFightingTypeName,  // 소방설비 일련번호
              sopFireFightingKindName: item.sopFireFightingKindName,  // 소방설비 일련번호
              manageNo: item.manageNo,  // 소방설비 일련번호
              // deptCd: this.$store.getters.user.deptCd,  // 점검부서
              // checkScheduleDate: this.$comm.getToday(),  // 점검예정일
              // checkDate: '',  // 점검일
              checkResult: '',  // 점검결과
              results: _results,
              regUserId: this.$store.getters.user.userId,  // 등록자 ID
              editFlag: 'C'
            })
          }
        })
        this.$http.url = this.insertTargetUrl;
        this.$http.type = 'POST';
        this.$http.param = this.check.targets
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.$emit('getDetail')
        },);
      }
    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.fft.fireFightingCheck.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.fft.fireFightingCheck.insert.url
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId;
              this.check.chgUserId = this.$store.getters.user.userId;
              this.$set(this.check, 'checkStartDate', this.check.period[0])
              this.$set(this.check, 'checkEndDate', this.check.period[1])

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingCheckId', result.data)
      this.$emit('getDetail');
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopFireFightingCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeInfo() {
      /**
       * 1. 점검대상이 하나 이상있는지?
       * 2. 점검대상에 점검일이 입력되어 있는지?
       */
      if (!this.check.targets || this.check.targets.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '점검대상이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      // else {
      //   let _filter = this.$_.filter(this.check.targets, (item) => {
      //     return !item.checkDate
      //   })
      //   if (_filter && _filter.length > 0) {
      //     window.getApp.$emit('ALERT', {
      //       title: '안내', // 안내
      //       message: '점검일이 입력되지 않은 점검대상이 있습니다..',
      //       type: 'warning', // success / info / warning / error
      //     });
      //     return;
      //   }
      // }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId;
              this.check.chgUserId = this.$store.getters.user.userId;
              this.$set(this.check, 'checkStartDate', this.check.period[0])
              this.$set(this.check, 'checkEndDate', this.check.period[1])

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopFireFightingCheckId', result.data)
      this.$emit('getDetail');
    },
  }
};
</script>