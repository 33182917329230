var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled && _vm.isOld,
                            expression: "editable&&!disabled&&isOld",
                          },
                        ],
                        attrs: { label: "삭제", icon: "delete" },
                        on: { btnClicked: _vm.deleteInfo },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled,
                            expression: "editable&&!disabled",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.check,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable && !_vm.disabled && _vm.isOld,
                            expression: "editable&&!disabled&&isOld",
                          },
                        ],
                        attrs: {
                          url: _vm.completeUrl,
                          isSubmit: _vm.isComplete,
                          param: _vm.check,
                          mappingType: "PUT",
                          label: "완료",
                          icon: "check",
                        },
                        on: {
                          beforeAction: _vm.completeInfo,
                          btnCallback: _vm.completeCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        label: "점검명",
                        name: "checkName",
                      },
                      model: {
                        value: _vm.check.checkName,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "checkName", $$v)
                        },
                        expression: "check.checkName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-select", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        codeGroupCd: "SOP_FIRE_FIGHTING_CHECK_TYPE_CD",
                        type: "edit",
                        itemText: "codeName",
                        itemValue: "code",
                        label: "점검유형",
                        name: "sopFireFightingCheckTypeCd",
                      },
                      model: {
                        value: _vm.check.sopFireFightingCheckTypeCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "sopFireFightingCheckTypeCd", $$v)
                        },
                        expression: "check.sopFireFightingCheckTypeCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.check.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "plantCd", $$v)
                        },
                        expression: "check.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2" },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: true,
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        range: "",
                        default: "today",
                        label: "점검기간",
                        name: "period",
                      },
                      model: {
                        value: _vm.check.period,
                        callback: function ($$v) {
                          _vm.$set(_vm.check, "period", $$v)
                        },
                        expression: "check.period",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _vm.isOld
        ? _c(
            "c-table",
            {
              ref: "table1",
              staticClass: "q-mt-sm",
              attrs: {
                title: "점검 대상",
                columns: _vm.grid.columns,
                data: _vm.check.targets,
                gridHeight: _vm.gridHeight,
                editable: _vm.editable && !_vm.disabled,
                filtering: false,
                columnSetting: false,
                isExcelDown: false,
                isTitle: true,
                usePaging: true,
                gridHeightAuto: true,
                selection: "multiple",
                rowKey: "sopFireFightingCheckTargetId",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name !== "custom"
                          ? [
                              col.name === "fireFightingName"
                                ? _c("q-btn", {
                                    staticClass: "tableinnerBtn",
                                    attrs: {
                                      flat: "",
                                      align: "center",
                                      color: "blue-6",
                                      label: props.row[col.name],
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.linkClick(props.row)
                                      },
                                    },
                                  })
                                : [
                                    _vm._v(
                                      " " + _vm._s(props.row[col.name]) + " "
                                    ),
                                  ],
                              _c(
                                "div",
                                { staticStyle: { "font-size": "0.8em" } },
                                [
                                  col.name === "sopFireFightingTypeName"
                                    ? [
                                        _vm._v(
                                          " (" +
                                            _vm._s(
                                              props.row[
                                                "sopFireFightingKindName"
                                              ]
                                            ) +
                                            ") "
                                        ),
                                      ]
                                    : _vm._e(),
                                  col.name === "fireFightingName"
                                    ? [
                                        _vm._v(
                                          " (" +
                                            _vm._s(props.row["manageNo"]) +
                                            ") "
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ],
                null,
                false,
                3199535461
              ),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "삭제", icon: "delete" },
                            on: { btnClicked: _vm.deleteTarget },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: { label: "추가", icon: "add" },
                            on: { btnClicked: _vm.addTarget },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }